import React, { useState } from 'react'
import { Accordion, Col, Container, Row } from 'react-bootstrap'
import { isDesktop, isMobile } from 'react-device-detect';
import { Link } from 'react-router-dom'

const Faq = () => {
    const accordionItems = [
        {
            eventKey: "0",
            header: "What is Dall-E Free?",
            body: "Dall-E Free is a website that provides a platform for accessing DALL-E-generated images for free in our free plan. It allows you to generate AI-powered images on the spot without you having to log in every time you need to generate one."
        },
        {
            eventKey: "1",
            header: "How does Dall-E Free work?",
            body: "Dall-E Free utilizes OpenAI DALL-E, a powerful AI model, to generate unique and high-quality images. Users can access the website and easily generate and download these DALL-E-generated images for their projects. You can insert a prompt and generate an image as per your liking."
        },
        {
            eventKey: "2",
            header: "Are Dall-E Generated Images On Dall-E Free Really Cost-Effective?",
            body: "Dall-E Free is budget-friendly because it uses smart technology to create images without wasting resources. It manages computer resources cleverly, uses efficient image-making methods, and takes advantage of cost-friendly cloud services. This combination of strategic measures ensures that Dall-E Free provides an affordable yet powerful solution for turning ideas into excellent visuals using the OpenAI API."
        },
        {
            eventKey: "3",
            header: "Can I use the DALL-E 2, DALL-E 3, generated images from Dall-E Free for commercial purposes?",
            body: "Yes, you are allowed to use the DALL-E-generated images from Dall-E Free for both personal and commercial projects."
        },
        // {
        //     eventKey: "4",
        //     header: "Is there a limit on the number of images I can generate per day?",
        //     body: "Depending on the plan you select, there will be a monthly limit on the number of images that can be generated. Our free plan allows for 5 image generations per month, while the Gold plan supports image generation of up to 1000 images. If you choose the Platinum plan, you will be able to generate up to 3000 images per month."
        // },
        // {
        //     eventKey: "5",
        //     header: "What types of prompts can I use to generate images?",
        //     body: "You can use a variety of prompts to instruct DALL-E Free in generating images. The more detailed and specific your prompt, the better the results. Experiment with different prompts to achieve the desired output."
        // },
        {
            eventKey: "6",
            header: "Is there a refund policy?",
            body: "You get a 1-day money-back guarantee on all plans. Submit your request via our contact form, and we will refund the payment made if the request is made within 1 days after the sign-up."
        },
        {
            eventKey: "7",
            header: "Can I cancel my subscription at any time?",
            body: "It's easy to cancel your subscription anytime from your account settings. Please note that the cancellation will take effect at the end of your current billing cycle."
        },
        {
            eventKey: "8",
            header: "Can I modify or edit the  DALL-E 2, DALL-E 3 generated images on Dall-E Free?",
            body: "Yes, once you have received a DALL-E-generated image from Dall-E Free, you have the freedom to modify and edit it according to your requirements. Feel free to customize and enhance the Dall-E images to suit your project's needs by re-entering the prompt and making a few modifications to your text."
        },
        {
            eventKey: "9",
            header: "How frequently can you generate  DALL-E 2, DALL-E 3 generated images on Dall-e Free?",
            body: "Dall-e Free allows you limitless access to generating Dall-e images. You just have to enter a prompt and the images will be generated."
        },
        {
            eventKey: "10",
            header: "What image formats are available for download on Dall-E Free?",
            body: "Dall-E Free primarily offers DALL-E 2, DALL-E 3 generated images in the PNG format, ensuring high-quality and transparent backgrounds. This format allows for easy integration into various design projects"
        }
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    const handleAccordionClick = (index) => {
      setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <section className="faq mt-5">
            <div className="container">
                <Row>
                    <Col md={4}>
                        <div className='faqTitle'>
                            <h2>Frequently Asked Questions</h2>
                            <p>Find answers to common queries quickly and easily in our comprehensive FAQ section.</p>
                            {isDesktop ? <Link to='/faq' className='theme-btn px-5 py-3'>View All</Link> : ' '}
                        </div>
                    </Col>
                    <Col md={8}>
                        <Accordion defaultActiveKey='0' flush>
                            {accordionItems.map((item, index) => (
                                <Accordion.Item eventKey={item.eventKey}
                                    className={activeIndex === index ? 'active' : ''}
                                    onClick={() => handleAccordionClick(index)}>
                                    <Accordion.Header>{item.header}</Accordion.Header>
                                    <Accordion.Body eventKey={item.eventKey}>
                                    {item.body}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))}
                            {/* <Accordion.Item eventKey="0">
                                <Accordion.Header>What is Dall-E Free?</Accordion.Header>
                                <Accordion.Body>
                                    Dall-E Free is a website that provides a platform for accessing DALL-E-generated images for
                                    free in our free plan. It allows you to generate AI-powered images on the spot without you
                                    having to log in every time you need to generate one.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>How does Dall-E Free work?</Accordion.Header>
                                <Accordion.Body>
                                    Dall-E Free utilizes OpenAI DALL-E, a powerful AI model, to generate unique and high-quality
                                    images. Users can access the website and easily generate and download these DALL-E-generated
                                    images for their projects. You can insert a prompt and generate an image as per your liking.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Are Dall-E Generated Images On Dall-E Free Really
                                    Cost-Effective?</Accordion.Header>
                                <Accordion.Body>
                                    Dall-E Free is budget-friendly because it uses smart technology to create images without
                                    wasting resources. It manages computer resources cleverly, uses efficient image-making
                                    methods, and takes advantage of cost-friendly cloud services. This combination of strategic
                                    measures ensures that Dall-E Free provides an affordable yet powerful solution for turning
                                    ideas into excellent visuals using the OpenAI API.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Can I use the DALL-E 2, DALL-E 3, generated images from Dall-E Free for
                                    commercial purposes?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, you are allowed to use the DALL-E-generated images from Dall-E Free for both personal
                                    and commercial projects.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Is there a limit on the number of images I can generate per
                                    day?</Accordion.Header>
                                <Accordion.Body>
                                    Depending on the plan you select, there will be a monthly limit on the number of images that
                                    can be generated. Our free plan allows for 5 image generations per month, while the
                                    Gold plan supports image generation of up to 1000 images. If you choose the Platinum
                                    plan, you will be able to generate up to 3000 images per month.
                                </Accordion.Body>
                            </Accordion.Item>



                            <Accordion.Item eventKey="5">
                                <Accordion.Header>What types of prompts can I use to generate images?</Accordion.Header>
                                <Accordion.Body>You can use a variety of prompts to instruct DALL-E Free in generating images. The more
                                    detailed and specific your prompt, the better the results. Experiment with different prompts
                                    to achieve the desired output.</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Is there a refund policy?</Accordion.Header>
                                <Accordion.Body> You get a 3-day money-back guarantee on all plans. Submit your request via our <Link to={'/contact-us'}><u><b>contact form</b></u></Link>, and we will refund the payment made if the request is made within 1 days after the sign-up. </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="7">
                                <Accordion.Header>Can I cancel my subscription at any time?</Accordion.Header>
                                <Accordion.Body>It's easy to cancel your subscription anytime from your account settings. Please note that
                                    the cancellation will take effect at the end of your current billing cycle.</Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="8">
                                <Accordion.Header>Can I modify or edit the DALL-E 2 generated images on Dall-E
                                    Free?</Accordion.Header>
                                <Accordion.Body>
                                    Yes, once you have received a DALL-E-generated image from Dall-E Free, you have the freedom
                                    to modify and edit it according to your requirements. Feel free to customize and enhance the
                                    Dall-E images to suit your project's needs by re-entering the prompt and making a few
                                    modifications to your text.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="9">
                                <Accordion.Header>How frequently can you generate DALL-E 2 generated images on
                                    Dall-e Free?</Accordion.Header>
                                <Accordion.Body>
                                    Dall-e Free allows you limitless access to generating Dall-e images. You just have to enter a
                                    prompt and the images will be generated.
                                </Accordion.Body>
                            </Accordion.Item> */}

                        </Accordion>
                        {isMobile ? <div className='m-auto d-table pt-5'><Link to='/faq' className='theme-btn px-5 py-3'>View All</Link></div> : ' '}

                    </Col>
                </Row>



            </div>
        </section>
    )
}

export default Faq