export const base_url = "https://api.dall-efree.com/api";
export const dalleActive = true;  // if it is true then dalle 3 works on size "1024*1024" only and if size is different then fal api will run!
export const dalle_2 = false; // if it is true then "dalleActive" will be required false to run dalle_2 on every request!
export const falActive = false; // if it is true the fal api run on every request!
export const fastspringActive = true; // This is for fastspring payment gateway!
export const Gold_subscription_id = "P-57H50688M4829033WM2PXKJI";
export const Gold_Yearly_subscription_id = "P-2EM414583K3113725MX6VWKI";
export const Platinum_subscription_id = "P-6TJ58565SH994544VM2PXQNI";
export const Platinum_Yearly_subscription_id = "P-73T350943S8912410MX6VXBA";
    

// Gold 10$ paypal plan id : P-57H50688M4829033WM2PXKJI

// Platinum 15$ paypal plan id : P-6TJ58565SH994544VM2PXQNI