import React from "react";
import PrivateRoute from "../comman/Privaterouter";
import Contactus from "../static_pages/ContactUs";
import { Helmet } from "react-helmet";
const HelpCenter = () => {
  return (
    <>
      <PrivateRoute />
      <Helmet>
        <title> Help Center | Dall-E</title>
      </Helmet>
      <section className="main-container-dashboard cont-dash pt-0">
        <div>
          <Contactus />
        </div>
      </section>
    </>
  );
};

export default HelpCenter;
