import React, { useEffect  } from 'react';

const AdsComponent = (props) => {
    const {width, height, dataAdSlot } = props;
    useEffect(() => {

        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        }
        catch (e) {
            console.log(e)
        }
    },[]);

    return (
        <>
            <ins className="adsbygoogle"
                style={{ display: "inline-block", width :`${width}px` , height: `${height}px` }}
                data-ad-client="ca-pub-5442643109134129"
                data-ad-slot={dataAdSlot}>
            </ins>
        </>
    );
};

export default AdsComponent;

// <AdsComponent dataAdSlot='2484037694' /> 